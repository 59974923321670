import { render, staticRenderFns } from "./goods_stock.vue?vue&type=template&id=653ce71d&scoped=true"
import script from "./goods_stock.vue?vue&type=script&lang=ts"
export * from "./goods_stock.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "653ce71d",
  null
  
)

export default component.exports

import { Component, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import { apiGoodsLibraryLists } from '@/api/goods'
import {RequestPaging} from "@/utils/util";
import LsPagination from '@/components/ls-pagination.vue'

@Component({
    components: {
        LsDialog,
      LsPagination
    }
})
export default class PayTemplate extends Vue {
    /** S Data **/
    paymentConfigData: Array<Object> = []
    /** E Data **/

    /** S Methods **/

    formData = {
      name: '',
      type: '',
      status: ''
    }

  pager = new RequestPaging()
  getPaymentConfigList(page?: number): void {
    page && (this.pager.page = page)
    this.pager.request({
      callback: apiGoodsLibraryLists,
      params: this.formData
    })
  }




    goPayConfigEdit(row: any) {
        this.$router.push({
            path: '/setting/payment/pay_config_edit',
            query: {
                id: row.id
            }
        })
    }
    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.getPaymentConfigList()
    }
}
